export const formatLoqateValues = (items) => {
    var _a;
    const itemArr = (_a = items.Items) !== null && _a !== void 0 ? _a : [];
    return itemArr.map((item) => {
        const formattedItem = item;
        return Object.assign(Object.assign({}, formattedItem), { value: formattedItem.Id, label: (item.Type === "Address") ? formattedItem.Text : `${formattedItem.Text} ${formattedItem.Description}` });
    });
};
export const formatAddressDetails = (addressDetails) => {
    var _a, _b, _c, _d, _e, _f;
    const address = {
        address_1: (_a = addressDetails.Line1) !== null && _a !== void 0 ? _a : "",
        address_2: (_b = addressDetails.Line2) !== null && _b !== void 0 ? _b : "",
        city: (_c = addressDetails.City) !== null && _c !== void 0 ? _c : "",
        state: (_d = addressDetails.Province) !== null && _d !== void 0 ? _d : "",
        country: (_e = addressDetails.CountryIso2) !== null && _e !== void 0 ? _e : "GB",
        postcode: (_f = addressDetails.PostalCode) !== null && _f !== void 0 ? _f : "",
    };
    if (typeof addressDetails.Company !== "undefined" && addressDetails.Company.length > 0) {
        address.company = addressDetails.Company;
    }
    return address;
};
